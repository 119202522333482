



























import { Component, Prop, Vue } from 'vue-property-decorator';

import TicketModel from '@/app/modules/tickets/models/TicketModel';
import DataTable from '@/shared/resources/components/tables/data-table/DataTable.vue';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';
import { DataTableHeader } from 'vuetify';
import Alert from '@/shared/resources/components/Alert.vue';

@Component({
  components: {
    Alert,
    DataTable,
  },
})
export default class TicketsTable extends Vue {
  /**
   * Props
   */
  @Prop() private tickets!: TicketModel[];
  @Prop() private error!: boolean;

  private template: TableTemplateInterface = {
    search: false,
    topPagination: false,
    bottomLimit: false,
  };

  private headers: DataTableHeader[] = [
    {
      value: 'name',
      text: 'Nazwa',
    },
    // {
    //   value: 'priceWithCurrency',
    //   text: 'Cena',
    // },
    // {
    //   value: 'amount',
    //   text: 'Stan',
    // },
  ];

  /**
   * Class names
   */
  private ticketPriceClassNames(ticket: TicketModel): string | null {
    if (!ticket.isSoldOut()) {
      return null;
    }

    return 'green--text';
  }
}
