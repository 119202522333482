

























































import { Component, Vue } from 'vue-property-decorator';

import Card from '@/shared/resources/components/cards/Card.vue';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import CardGroup from '@/shared/resources/components/cards/CardGroup.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Header from '@/shared/resources/components/Header.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Loader from '@/shared/resources/components/Loader.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import ImgViewer from '@/shared/resources/components/ImgViewer.vue';
import TicketsTable from '@/app/modules/tickets/components/TicketsTable.vue';
import TicketModel from '@/app/modules/tickets/models/TicketModel';

@Component({
  components: {
    TicketsTable,
    ImgViewer,
    Icon,
    Loader,
    GridRow,
    GridCol,
    Header,
    Button,
    CardGroup,
    MainHeader,
    Card,
  },
})
export default class DashboardView extends Vue {
  /**
   * Data
   */
  private tickets: TicketModel[] | null = null;
  private ticketsError: boolean = false;

  private photoPath: string | null = null;

  /**
   * Display getters
   */
  private get displayTicketsTable(): boolean {
    return !!this.tickets;
  }

  /**
   * Lifecycle hooks
   */
  private async created() {
    try {
      this.tickets = (await TicketModel
          .apiExt()
          .fetch()
      ).items;

      this.photoPath = this.tickets[0]?.photo;
    } catch (ex) {
      this.ticketsError = true;
    }
  }
}
